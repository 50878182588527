import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'components/layout'
import SEO from 'components/seo'
import JobHero from 'components/JobHero'
import ApplicationForm from 'components/ApplicationForm'

const Job = ({ pageContext: { job } }) => {
  return (
    <Layout>
      <SEO title={`Apply for ${job.title}`} />
      <JobHero job={job} showButtons={false} />
      <ApplicationForm job={job} />
    </Layout>
  )
}

Job.propTypes = {
  pageContext: PropTypes.shape({
    job: PropTypes.object.isRequired,
    groupedJobs: PropTypes.object,
  }).isRequired,
}

export default Job
